
import './App.css';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Home from './components/Home';
import OurFleets from './components/pages/OurFleets';

import {Route, BrowserRouter as Router, Routes, useNavigate} from 'react-router-dom';
import {HelmetProvider} from "react-helmet-async";
import Login from './components/user/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from './components/user/Register';
import {loadUser} from './actions/userActions';
import { useEffect, useState } from 'react';
import store from './store';
import Profile from './components/user/Profile';
import ProtectedRoute from './components/route/ProtectedRoute';
import UpdateProfile from './components/user/UpdateProfile';
import UpdatePassword from './components/user/UpdatePassword';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import Payment from './components/orders/Payment.js';
import axios from 'axios';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import BookingForm from './components/booking/BookingForm.js';
import { getVehicles } from './actions/vehicleActions.js';
import PaymentPayNowQr from './components/orders/PaymentPayNowQr.js';
import OurServices from './components/pages/OurServices.js';
import Navbar from './components/layouts/Navbar.js';
import Ratings from './components/pages/Ratings.js';
import PaymentPaypalCard from './components/orders/paypal/PaymentPaypalCard.js';
import PaypalPaymentPage from './components/orders/paypal/PaypalPaymentPage.js';
import HitpaySuccess from './components/orders/hitpay/HitpaySuccess.js';
import PaymentHitpay from './components/orders/hitpay/PaymentHitpay.js';
import TermsAndConditions from './components/pages/TermsAndConditions.js';
import { getHourlyData } from './actions/hourlyDataActions.js';
import { getSubServices } from './actions/subServiceActions.js';
import { getServices } from './actions/serviceActions.js';
// const path = require('path');


function App() {
  // const apiUrl = process.env.REACT_APP_API_URL;
  // const environment = process.env.REACT_APP_ENVIRONMENT;
  // const allowedValues = process.env.REACT_APP_ALLOWED_VALUES
  //   ? process.env.REACT_APP_ALLOWED_VALUES.split(',')
  //   : [];

  // console.log('API URL:', apiUrl);
  // console.log('Environment:', environment);
  // console.log('Allowed Values:', allowedValues);
  // dotenv.config({path : path.join(__dirname, "../../backend/config/config.env")});
  
  const [stripeApiKey, setStripeApiKey] = useState('');
  useEffect(() => {

    // store.dispatch(loadUser);
    store.dispatch(getVehicles);

    store.dispatch(getServices);
       
    store.dispatch(getSubServices);
       
    store.dispatch(getHourlyData);

    // async function getStripeApiKey() {
    //   try {
    //     const {data} = await axios.get('api/v1/stripeapi');
    //     setStripeApiKey(data.stripeApiKey); 
    //     console.log("stripe api key done") 
    //   } catch (error) {
    //     console.log("Stripe key get axios Error : ", error)
    //     getStripeApiKey()
    //   }
     
    // }
    
    // getStripeApiKey()
    // console.log('stripeApiKey : ', stripeApiKey)
    

    // console.log('process.env.WELCOME : ', process.env.WELCOME)
    
  }, [])

  return (
    <Router>
    <div className="App">
      <HelmetProvider>
      {/* <Header/> */}
      <Navbar/>
      <ToastContainer theme="dark"/>
      <Routes>

        <Route path='/' element={<Home/>} />
        <Route path='/login' element={<Login/>} />
        <Route path='/register' element={<Register/>} />
        <Route path='/myprofile' element={ <ProtectedRoute> <Profile/> </ProtectedRoute> } />
        <Route path='/myprofile/update' element={ <ProtectedRoute> <UpdateProfile/> </ProtectedRoute> } />
        <Route path='/myprofile/update/password' element={ <ProtectedRoute> <UpdatePassword/> </ProtectedRoute> } />
        <Route path='/password/forgot' element={  <ForgotPassword/> } />
        <Route path='/password/reset/:token' element={  <ResetPassword/> } />
        <Route path='/booknow' element={  <BookingForm/>   } />
        <Route path='/our-fleets' element={<OurFleets/>} />
        <Route path='/our-services' element={<OurServices/>} />
        <Route path='/ratings' element={ <ProtectedRoute> <Ratings/> </ProtectedRoute> } />

        <Route path='/paypal-card-payment' element={  <PaymentPaypalCard/> } />
        <Route path='/paypal-page' element={  <PaypalPaymentPage/> } />
        <Route path='/hitpay-success' element={  <HitpaySuccess/> } />

        <Route path='/hitpay-payment-paynow-qr' element={  <PaymentHitpay/> } />

        <Route path='/terms-conditions' element={  <TermsAndConditions/> } />

        
        
        
        { stripeApiKey && <Route path='/payment' 
        element={  <Elements stripe={loadStripe(stripeApiKey)}> <Payment/> </Elements>  } />
        }
        { stripeApiKey && <Route path='/paymentqr' 
        element={ <Elements stripe={loadStripe(stripeApiKey)}> <PaymentPayNowQr/> </Elements> } />
        }
      </Routes>
      <Footer />
      </HelmetProvider>
    </div>
    </Router>
  );
}

export default App;
