import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthError } from "../../actions/userActions";
import { toast } from "react-toastify";
import { createRating } from "../../actions/ratingsAction";
import { clearRatingCreated, clearRatingError } from "../../slices/ratingSlice";

export default function StarRate(props) {

  const [ratingNumber, setRatingNumber] = useState(0);
  const [commentText, setCommentText] = useState("");
  const [submitBtnState, setSubmitBtnState] = useState(false);

  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated, user } = useSelector(
    (state) => state.authState
  );

  const { loading : ratingLoading, ratings, result, isRatingCreated, error : ratingError  } = useSelector(
    (state) => state.ratingsState
  );

  useEffect(() => {
    if (!isAuthenticated) {
        navigate("/login");
      }


      if (isRatingCreated) {
        toast("Ratings added", {
          position: "bottom-center",
          type: "success",
          onOpen: () => {
            setTimeout(() => {
              setSubmitBtnState(false)
              props.reloadFunc()
            }, "5000");
            dispatch(clearRatingCreated());
          },
        });
        return;
      }
  
    //   if (error) {
    //     toast(error, {
    //       position: "bottom-center",
    //       type: "error",
    //       onOpen: () => {
    //         dispatch(clearAuthError);
    //       },
    //     });
    //     return;
    //   }

      if (ratingError) {
        toast(ratingError, {
          position: "bottom-center",
          type: "error",
          onOpen: () => {
            setSubmitBtnState(false)
            dispatch(clearRatingError);
          },
        });
        return;
      }


  }, [
    error,
    isAuthenticated,
    dispatch,
    isRatingCreated,
  ])

  const submitHandler = (e) => {
    

    e.preventDefault();
    //console.log("inside submit")
    if(ratingNumber === 0){
        toast("Please give the rating", {
            position: "bottom-center",
            type: "error",
            onOpen: () => {
              setTimeout(() => {
                setSubmitBtnState(true)
              }, "5000");
              dispatch(clearRatingCreated());
              setSubmitBtnState(false)
            },
          });
        return
    }

    setSubmitBtnState(true)

    //console.log("star & comment : ", ratingNumber , "...  ",commentText )
    //console.log("user : ", user.name)
        dispatch(createRating({
           "title": "RATINGS",
           "comment": commentText,
           "rating": ratingNumber 
        }))

        setRatingNumber(0);
        setCommentText("")
  }
  
  return (
    <>
      <h2 className="text-center py-lg-4 py-3">Rating</h2>
      <div className="h-100 d-flex align-items-center justify-content-center">
        

        <Rating

        placeholderRating={ratingNumber}

        placeholderSymbol={<img src="./images/ratings/blue-star.svg" 
        width="35rem"    
        className="icon" />}

        onChange={(e)=> { setRatingNumber(e)}}

          emptySymbol={
            <img
              src="./images/ratings/blue-star-empty.svg"
              width="35rem"
              className="icon"
            />
          }
          fullSymbol={
            <img
              src="./images/ratings/blue-star.svg"
              width="35rem"
              className="icon"
            />
          }
          fractions={2}
        />
      </div>
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div class="form-group rating-comment">
          <label for="exampleFormControlTextarea1" style={{color:"#091057"}} > Add your comment </label>
          <textarea
          value={commentText}
          onChange={(e) => {setCommentText(e.target.value)}}
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            disabled={false}
          ></textarea>
        </div>

      </div>

      <div className="h-100 d-flex align-items-center justify-content-center mb-lg-5 mb-2">
      <button type="button" class="btn btn-primary px-5" style={{color:"#fff", backgroundColor:"#091057"}} 
      onClick={(e)=> {submitHandler(e)}}
      disabled={submitBtnState}
      >Submit</button>
      </div>
      
    </>
  );
}
