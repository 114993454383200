import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

const TermsAndConditions = () => {
  return (
    <div
      style={{ backgroundColor: "#ffffff", color: "black", padding: "20px" }}
    >
      <div className="container">
        <h1 className="text-center" style={{ color: "#091057" }}>
          Terms and Conditions
        </h1>
        <div
          className="card mt-4"
          style={{ backgroundColor: "#C6CEE0", color: "#091057" }}
        >
          <div className="card-body">
            <h5>Advance Booking</h5>
            <ul >
              <li>
                We strongly recommend booking in advance to secure your
                preferred vehicle and minimize waiting time.
              </li>
            </ul>

            <h5>Last Minute Bookings</h5>
            <ul>
              <li>
                Last minute booking (within 10 minutes of the request pick-up)
                may result in longer waiting time ( 15 - 30 minutes depending on
                traffic and vehicle availability).
              </li>

            </ul>

            <h5>Waiting Time</h5>
            <ul>
            <li>
                A complimentary waiting time of 10
                minutes is provided for all transfers.
              </li>
            </ul>


            <h5>Vehicle Assignment</h5>
            <ul>
            <li>
                The fastest available vehicle
                will be assigned within 10 to 20 minutes.
              </li>
              
            </ul>

            <h5>Early Morning Bookings</h5>
            <ul>
            <li>
                For bookings between 2300Hrs
               and 0630Hrs please book by 1900Hrs the previous day to
                guarantee service.
              </li>
              
            </ul>

            <h5>Midnight Surcharge</h5>
            <ul>
            <li style={{paddingBottom: '0.5rem'}}>
            6 To 13 Seater 2330Hrs To 0630Hrs Additional <b>SGD10</b> 

              </li>
            <li>

            23 & 40 Seater 2330Hrs To 0630Hrs Additional <b>SGD30</b>
              </li>
            </ul>

            <h5>Hourly Transfers</h5>
            <ul>
            <li>
                A midnight surcharge of <b>SGD10</b> per
                hour will be applied for hourly transfers between 2300Hrs and
                0630Hrs
              </li>
            </ul>


            <h5>Additional Charges</h5>
            <ul>
              <li>
                Carpark, Electronic Road Pricing (ERP), and other related
                charges incurred during the trip will be invoiced at the
                conclusion of the journey.
              </li>
              
            </ul>
            
            <h5>Prepayment</h5>
            <ul>
            <li>
                Mandatory for all bookings made on
                behalf of guests.
              </li>
            </ul>

            


            

            

            <h5>Event Surcharge</h5>
            <ul>
            <li>
                Fares are subject to a 40%
                surcharge during events such as the Formula 1 race, New Year's
                Eve, Airshow, and other major holidays in Singapore.
                Notification for reconfirmation will be provided.
              </li>
            </ul>

            <h5>Vehicle Availability</h5>
            <ul>
            <li>
                If no vehicle is available for
                your booking, we will arrange the next higher capacity vehicle
                at no extra cost. Please indicate any specific vehicle
                preferences in the booking page's message section.
              </li>
            </ul>


            <h5>Payment Methods</h5>
            <ul>
            <li>
                Accepted payment methods include
                Credit Card, Bank Transfer, PayNow, and Cash.
              </li>
            </ul>


            <h5>No Show</h5>
            <ul>
            <li>
                Corporate and company bookings passenger
                no shows on the day of pick-up will incur a 100% cancellation
                fee.
              </li>
            </ul>

            <h5>Service Fee</h5>
            <ul>
            <li>
                A 5% service fee applies to any booking
                cancellations made less than 4 hours before the scheduled
                pick-up time.Cancellations or amendments should be notified at least 12
                hours before the scheduled pick-up time.
              </li>
              
            </ul>

            <h5>Service Hours</h5>
            <ul>
            <li>
                Our standard service hours are
                0500Hrs to 0000Hrs. Services outside of standard hours
                will incur additional fees.
              </li>

            </ul>

            <h5>ERP and Parking Fees</h5>
            <ul>
            <li>
                Not included in the initial
                quote and will be charged separately.
              </li>

            </ul>


            <h5>Damages</h5>
            <ul>
            <li>
                Any damages to the vehicle or interior
                caused by passengers will be charged.
              </li>

            </ul>

            <h5>Liability</h5>
            <ul>
            <li>
                We are not liable for any delays or cancellations caused by unforeseen circumstances, such as traffic, weather, or mechanical issues
              </li>
            </ul>

              {/* //pending */}

            <ul>
              
              
              
              
              
              
              
              
            </ul>
          </div>
        </div>

        <h2 className="text-center mt-5" style={{ color: "#091057" }}>
          Passenger Capacity and Luggage
        </h2>
        <div className="table-responsive">
          <table className="table table-bordered" style={{ color: "#091057" }}>
            <thead>
              <tr style={{ backgroundColor: "#091057", color: "#fff" }}>
                <th>Vehicle</th>
                <th>Passenger Capacity</th>
                <th>Luggage Capacity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6 Seater Standard MPV</td>
                <td>4 Pax</td>
                <td>4 Mid-Size Luggage</td>
              </tr>
              <tr>
                <td>6-Seater MaxiCab Premium Car</td>
                <td>5 Pax</td>
                <td>5 Cabin Sized Luggage</td>
              </tr>
              <tr>
                <td>7-Seater MaxiCab</td>
                <td>7 Pax</td>
                <td>7 Mid-Sized Luggage</td>
              </tr>
              <tr>
                <td>9-Seater Minibus</td>
                <td>9 Pax</td>
                <td>9 Mid-Sized Luggage</td>
              </tr>
              <tr>
                <td>13-Seater Minibus</td>
                <td>13 Pax</td>
                <td>Hand Carry Bags</td>
              </tr>
              <tr>
                <td>23-Seater Coach</td>
                <td>23 Pax</td>
                <td>4 Mid-Sized Luggage</td>
              </tr>
              <tr>
                <td>40-Seater Coach</td>
                <td>40 Pax</td>
                <td>25 Mid-Sized Luggage</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
